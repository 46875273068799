<template>
  <article class="page page_wrap">
    <hooper :settings="hooperSettings" :style="{ height: 'auto' }">
      <slide v-for="(img, index) in carouselImages" :key="index">
        <section
          class="bg_full"
          :style="{
            'background-image': `url(${$imgUrl(img)})`,
          }"
        />
      </slide>
      <hooper-pagination slot="hooper-addons" />
    </hooper>
    <section class="label bg_white">
      <div class="label_row" v-if="locale === 'pl'">
        <div class="item">
          <h1 class="heading-h1 horse_name">
            {{ $t("pages.offering.title") }}
          </h1>
          <p>{{ $t("pages.offering.header.text1") }}</p>
        </div>
        <div class="item bg_alternative row u_padding-30">
          <img :src="$imgUrl('feeds/panto.png')" alt="feed" />
          <div class="feeds_info">
            <h3 class="heading-h3">
              {{ $t("pages.offering.feed.title") }}
            </h3>
            <p class="list">
              {{ $t("pages.offering.feed.text") }}
            </p>
            <router-link tag="a" :to="{ path }">
              <button class="button_primary">
                {{ $t("pages.home.button_main") }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <template v-else>
        <h1 class="heading-h1 horse_name">
          {{ $t("pages.offering.title") }}
        </h1>
        <div class="label_row">
          <p class="item_column-2">
            {{ $t("pages.offering.header.text1") }}
          </p>
        </div>
      </template>
    </section>
    <div class="middle">
      <section class="content_box u_margin-top-60">
        <h2 class="heading-h1 u_text-center">
          {{ $t("pages.offering.title1") }}
        </h2>
        <p v-if="locale === 'pl'">
          {{ $t("pages.offering.main.text1") }}
        </p>
        <p>{{ $t("pages.offering.main.text2") }}</p>
        <youtube
          v-if="locale === 'pl'"
          :video-id="getIdFromURL('https://youtube.com/7-25dZzcQIk')"
          class="youtube-video-free"
        />
        <p>{{ $t("pages.offering.main.text3") }}</p>
        <template v-if="locale === 'eng'">
          <youtube
            :video-id="getIdFromURL('https://youtube.com/7-25dZzcQIk')"
            class="youtube-video-free"
          />
          <p>{{ $t("pages.offering.main.text4") }}</p>
          <p>{{ $t("pages.offering.main.text5") }}</p>
          <youtube
            :video-id="getIdFromURL('https://youtube.com/C3iLfpRgGUs')"
            class="youtube-video-free"
          />
          <p>{{ $t("pages.offering.main.text6") }}</p>
          <youtube
            :video-id="getIdFromURL('https://youtube.com/4EVW48zPw64')"
            class="youtube-video-free"
          />
          <p class="u_margin-bottom">{{ $t("pages.offering.main.text7") }}</p>
        </template>
        <template v-else>
          <youtube
            :video-id="getIdFromURL('https://youtube.com/C3iLfpRgGUs')"
            class="youtube-video-free"
          />
          <p>{{ $t("pages.offering.main.text4") }}</p>
          <youtube
            :video-id="getIdFromURL('https://youtube.com/4EVW48zPw64')"
            class="youtube-video-free"
          />
        </template>
      </section>
    </div>
  </article>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import { getIdFromURL } from "vue-youtube-embed";
import { trim } from "lodash";

export default {
  data() {
    return {
      hooperSettings: {
        centerMode: true,
        infiniteScroll: true,
        wheelControl: false,
        autoPlay: true,
        playSpeed: "5000",
        hoverPause: false,
        trimWhiteSpace: true,
      },
      carouselImages: [
        "offering/offering_1.jpg",
        "offering/offering_2.jpg",
        "offering/offering_3.jpg",
        "offering/offering_4.jpg",
        "offering/offering_6.jpg",
        "offering/offering_7.jpg",
      ],
      path: `/${trim(this.$t("menu.url.feeds"))}`,
    };
  },
  computed: {
    locale: ({ $i18n }) => $i18n.locale,
  },
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  methods: {
    getIdFromURL,
  },
};
</script>
